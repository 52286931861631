import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import BreadCrumbs from "../components/bread-crumbs";
import './article-details.scss';


const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Learning Library",
        link: "/learning-library",
    }

]

var Loader = require('react-loader');
export default (props) => {
    const {data} = props;
    const categoriesData = data.contentfulCategory.topics.filter(
        function (item) {
            return item.publishOnWebsite;
        });
    return (
        <Layout location={props.location}>
            <SEO title={data.contentfulCategory.name}/>
            <BreadCrumbs currentPage={data.contentfulCategory.name} breadCrumbsList={breadCrumbsList}/>
            <div className="lib-topic-wrapper">
                <h2 className="heading2">{data.contentfulCategory.name}</h2>

                <div className="topic-inner">
                    <div className="topic-list">
                        {
                            categoriesData ?
                                categoriesData.length > 0 ?
                                    categoriesData.map((items) => {

                                        return (
                                            <div className="single-topic">
                                                <div className="gre-area">
                                                    {items.icon !== null ?
                                                        <img
                                                            className="topic-icon"
                                                            src={`${items.icon.file.url}`}
                                                            alt="Topic Icon"/>
                                                        :
                                                        <img
                                                            className="topic-icon"
                                                            src={require(
                                                                '../assets/images/med_icon.png')}
                                                            alt="Topic Icon"/>
                                                    }
                                                </div>
                                                <div className="gre-content">
                                                    <h4 className="heading4">{items.name}</h4>
                                                </div>
                                                <Link className="topic-click"
                                                      to={`/${props.pageContext.slug}/${items.slug}/`}></Link>
                                            </div>
                                        )
                                    }) : <p>No Record Found </p> : <Loader
                                    loaded={false}
                                    options={this.state.loader.options}/>
                        }
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export const query = graphql`
query categoryById($slug:String) {
    contentfulCategory(slug: {eq:$slug}) {
      id
      name
      topics {
        name
        slug
        publishOnWebsite
        icon {
            file {
              url
            }
          }
        educationOrder {
          id
        }
      }
    }
  }
`;
